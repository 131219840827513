export const driver = {
  get: {
    url: "user/getDrivers",
    method: "GET",
    id: null,
    params: null,
  },
  getById: {
    url: "user/getDriverById/",
    method: "GET",
    id: null,
    params: null,
  },
  getUnAssignedDrivers: {
    url: "campaign/getUnAssignedDrivers",
    method: "GET",
    params: null,
  },
  createDriver: {
    url: "user/driver/newDriver",
    method: "POST",
    data: null,
  },
  assignWorkstation: {
    url: "admin/campaignAd/v2/assignWorkstation",
    method: "POST",
    data: null,
  },
  addBonusToDriver: {
    url: "admin/driver/addBonusToDriver",
    method: "POST",
    data: null,
  },
  changeCampaign: {
    url: "admin/campaign/changeCampaign",
    method: "POST",
    data: null,
  },
  vehicleAd: {
    url: "admin/application/v2/drivers",
    method: "GET",
    params: null,
  },
  updateCompany: {
    url: "user/driver/updateCompany",
    method: "POST",
    params: null,
  },
  updateLicenseInfo: {
    url: "user/driver/updateLicenseInfo",
    method: "POST",
    params: null,
  },
  getModelsandMakes: {
    url: "admin/vehicle/getModelsandMakes",
    method: "GET",
  },
  updateVehicleDetails: {
    url: "/admin/vehicle/updateVehicleDetails",
    method: "POST",
  },
  updatePersonalInfo: {
    url: "user/driver/updateDriverContactInfo",
    method: "POST",
    params: null,
  },
  terminal_info: {
    url: "tms/updateSerialNo",
    method: "POST",
    params: null,
  },
  changeLocation: {
    url: "/admin/vehicle/driver/updateLocation",
    method: "POST",
    data: null,
  },
};
