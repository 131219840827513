export const campaigns = {
  list: {
    url: "/campaign/listCampaigns",
    method: "GET",
    params: null,
  },
  getUnassigned: {
    url: "/campaign/unAssignedCampaigns",
    method: "GET",
    params: null,
  },
  verify: {
    url: "campaign/verifyCampaign",
    method: "POST",
    data: null,
  },
  uploadDesign: {
    url: "/campaign/uploadDesign",
    method: "POST",
    params: null,
    data: null,
  },
  uploadSample: {
    url: "/campaign/uploadSample",
    method: "POST",
    params: null,
    data: null,
  },
  printed: {
    url: "/campaign/printDone",
    method: "POST",
    camp_id: null,
    params: null,
  },
  assignDriver: {
    url: "/campaign/assignDriverToCampaign",
    method: "POST",
    data: null,
  },
  getCampaignDetails: {
    url: "/campaign/getCampaignDetails/",
    method: "GET",
    id: null,
    params: null,
  },

  getAssignedDrivers: {
    url: "/campaign/getDriversBasedOnCampaigns/",
    method: "GET",
    id: null,
    params: null,
  },
  removeAssignedDriver: {
    url: "/campaign/removeDriverFromCampaign",
    method: "POST",
    id: null,
    data: null,
  },
  designDone: {
    url: "/campaign/designDone",
    method: "POST",
    id: null,
    data: null,
  },
  designDoneV2: {
    url: "admin/campaign_design/finalise_design",
    method: "POST",
    id: null,
    data: null,
  },
  upload_design: {
    url: "admin/campaign_design/add_new_sticker",
    method: "POST",
    id: null,
    data: null,
  },
  verify_design: {
    url: "admin/campaign_design/verify_design",
    method: "POST",
    id: null,
    data: null,
  },
  remove_design: {
    url: "admin/campaign_design/remove_design",
    method: "POST",
    id: null,
    data: null,
  },
  approveDesign: {
    url: "/campaign/approveDesign",
    method: "POST",
    id: null,
    data: null,
  },
  rejectDesign: {
    url: "/campaign/rejectDesign",
    method: "POST",
    id: null,
    data: null,
  },
  rejectDesignV2: {
    url: "/admin/campaign_design/reject_design",
    method: "POST",
    id: null,
    data: null,
  },
  getCampaignTickets: {
    url: "/campaign/getCampaignTickets/",
    method: "GET",
    id: null,
  },
  createCampaignTicket: {
    url: "/campaign/addTicket",
    method: "POST",
    data: null,
  },
  campaignPayments: {
    url: "/payment/campaignPayments/",
    method: "GET",
    id: null,
  },
  addBonus: {
    url: "/campaign/addBonus",
    method: "POST",
    data: null,
  },
  unpaidCampaigns: {
    url: "/admin/campaign/unpaidCampaigns",
    method: "GET",
    params: null,
  },
  addPaymentAdmin: {
    url: "/payment/addPaymentAdmin",
    method: "POST",
    data: null,
  },
  changeStartDate: {
    url: "admin/campaign/changeStartWithin",
    method: "POST",
    data: null,
  },
  createCampaign: {
    url: "admin/campaign/addNewCampaign",
    method: "POST",
    data: null,
  },
  finishCampaign: {
    url: "admin/ads/campaign/finishCampaign",
    method: "POST",
    data: null,
  },
  updatePrmium: {
    url: "admin/ads/campaign/updatePremium",
    method: "POST",
    data: null,
  },
  deleteCampaign: {
    url: "admin/campaign/deleteCampaign/",
    method: "GET",
    id: null,
  },
  changeSampleImage: {
    url: "admin/campaign/changeSampleImage/",
    method: "PUT",
    id: null,
    data: null,
  },
  changeFlatRate: {
    url: "admin/campaign/changeFlatRate",
    method: "POST",
    data: null,
  },
  getCampaignForDriver: {
    url: "admin/campaign/getAvailableCampaigns/",
    method: "GET",
    id: null,
  },
  changeRecurrence: {
    url: "admin/campaign/changeRecurrence",
    method: "POST",
    data: null,
  },
  changeNoOfDays: {
    url: "admin/campaign/changeNoOfDays/",
    method: "PUT",
    id: null,
    data: null,
  },
  updatecampaign: {
    url: "admin/ads/campaign/updateCampaign/",
    method: "PUT",
    id: null,
    data: null,
  },
  regionlist: {
    url: "/region",
    method: "GET",
    id: null,
    data: null,
  },
  changeNoOfDrivers: {
    url: "admin/campaign/changeNoOfDrivers",
    method: "POST",
    data: null,
  },
  completeJob: {
    url: "admin/campaign/completeJob",
    method: "POST",
    data: null,
  },
  changeIndividualNoOfDays: {
    url: "admin/campaign/changeIndividualNoOfDays",
    method: "POST",
    data: null,
  },
  uploadDesignV2: {
    url: "/admin/campaign_design/upload_file",
    method: "POST",
    params: null,
    data: null,
  },
  uploadCampaignImage: {
    url: "/admin/ads/campaign/uploadCampaignImage",
    method: "POST",
    params: null,
    data: null,
  },
  deleteCampaignImage: {
    url: "/admin/ads/campaign/deleteCampaignImage",
    method: "POST",
    params: null,
    data: null,
  },
  getCampaignDesignV2: {
    url: "/admin/campaign_design/get_campaign_sticker/",
    method: "GET",
    id: null,
    params: null,
  },
  changeStickerV2: {
    url: "/admin/campaign/change_ad_sticker",
    method: "POST",
    id: null,
    data: null,
  },
  assignDriverv2: {
    url: "admin/campaign/v2/assignDriverToCampaign",
    method: "POST",
    data: null,
  },
  changeAdDate: {
    url: "admin/campaign/change_ad_date",
    method: "POST",
    data: null,
  },
  updateStickerType: {
    url: "vehicle/update_sticker",
    method: "POST",
    data: null,
  },
  completeDriverAd: {
    url: "admin/campaign/completeDriverAd",
    method: "POST",
    data: null,
  },
  updatePlanPrice: {
    url: "admin/campaign/updateCampaignAmount",
    method: "POST",
    data: null,
  },
  changeShowDriverDetailStatus: {
    url: "admin/campaign/changeShowDriverDetailStatus",
    method: "POST",
    data: null,
  },
  getCampaignLocations:{
    url: "heatMapLocations",
    method: "GET",
    params: {
      campaignId: null,
      start_date: null,
      end_data: null,}
  }
  
};
